<template>
  <div
    v-loading.fullscreen.lock="test_loading"
    :element-loading-text="loading_text"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <CContainer class="p-4">
      <CToaster :autohide="5000" v-if="toast_show">
        <template>
          <CToast :key="`toast-msg-${toast_index}`" :show="true" :color="toast_color">{{toast_msg}}</CToast>
        </template>
      </CToaster>

      <CButton color="secondary" @click="$router.push('/')">Home</CButton>

      <CCard class="w-100 my-2" v-if="subscribingDelivery">
        <CCardHeader>
          <strong>배송 관리 테스트 데이터 생성</strong>
        </CCardHeader>
        <CCardBody>
          <CRow class="p-4 justify-content-center">
            <div class="w-50">
              <CInput type="number" v-model="num_event" label="배송 내역 생성" horizontal :min="1" :max="1000000" />
              <CInput type="number" v-model="add_date" label="최근 몇일 이내" horizontal :min="1" :max="730" />
            </div>
            <div class="w-25 mx-4">
              <CButton color="primary" @click="onAddDeliveryEvent" :disabled="this.capability && !this.capability.user_profile.organization_id"><CIcon name="cil-plus" />&nbsp;Add</CButton>
            </div>
          </CRow>
        </CCardBody>
      </CCard>

      <CCard class="w-100 my-2" v-if="subscribingParking">
        <CCardHeader>
          <strong>주차 관리 테스트 데이터 생성</strong>
        </CCardHeader>
        <CCardBody>
          <CRow class="p-4 justify-content-center">
            <div class="w-50">
              <CInput type="number" v-model="num_event" label="입차/출차 이력 생성" horizontal :min="1" :max="1000000" />
              <CInput type="number" v-model="add_date" label="최근 몇일 이내" horizontal :min="1" :max="730" />
            </div>
            <div class="w-25 mx-4">
              <CButton color="primary" @click="onAddParkingEvent" :disabled="this.capability && !this.capability.user_profile.organization_id"><CIcon name="cil-plus" />&nbsp;Add</CButton>
            </div>
          </CRow>
          <el-divider />
          <CRow class="p-4 justify-content-center">
            <div class="w-50">
              <CInput type="number" v-model="num_event" label="점유 상태 기록 생성" horizontal :min="1" :max="1000000" />
              <CInput type="number" v-model="add_date" label="최근 몇일 이내" horizontal :min="1" :max="730" />
            </div>
            <div class="w-25 mx-4">
              <CButton color="primary" @click="onAddParkingOccupancy" :disabled="this.capability && !this.capability.user_profile.organization_id"><CIcon name="cil-plus" />&nbsp;Add</CButton>
            </div>
          </CRow>
        </CCardBody>
      </CCard>
    </CContainer>
  </div>
</template>

<script>
import axios from 'axios';
import qs from 'qs';
import { mapState } from 'vuex';

export default {
  name: 'Test',

  computed: {
    ...mapState([
      'capability'
    ]),
    subscribingDelivery() {
      if (!(this.capability && this.capability.organization)) return false;

      const subscriptions = this.capability.organization.subscription;
      if (subscriptions.length <= 0) return false;

      const service = subscriptions.find(el => el.service_key === 'delivery');
      if (!service) return false;

      const remain_hours = this.$utility.SubscriptionRemainHour(service);
      if (remain_hours <= 0) return false;

      return true;
    },
    subscribingParking() {
      if (!(this.capability && this.capability.organization)) return false;

      const subscriptions = this.capability.organization.subscription;
      if (subscriptions.length <= 0) return false;

      const service = subscriptions.find(el => el.service_key === 'parking');
      if (!service) return false;

      const remain_hours = this.$utility.SubscriptionRemainHour(service);
      if (remain_hours <= 0) return false;

      return true;
    },
  },
  mounted() {
    if (!this.capability) {
      this.$router.push('/pages/500');
      return;
    }
    if (this.capability.user_profile.auth_level >= 10) {
      this.$router.push('/pages/500');
      return;
    }
    this.getFreight()
    this.getCorrespondent()
  },
  data() {
    return {
      test_loading: false,
      loading_text: null,

      num_event: 1,
      num_done: 0,

      toast_show: false,
      toast_index: 1,
      toast_msg: '',
      toast_color: 'success',

      freight_list: [],
      correspondent_list: [],
      add_date: 60
    }
  },
  methods: {
    onAddDeliveryEvent: function() {
      this.loading_text = `테스트 데이터 추가중입니다... (${this.num_done}/${this.num_event})`;
      this.test_loading = true;
      if (this.num_done >= this.num_event) {
        this.test_loading = false;
        this.toast_show = true;
        this.toast_msg = `${this.num_event} 개의 테스트 데이터가 추가되었습니다.`;
        this.toast_index++;
        this.toast_color = 'success';
        this.num_done = 0;
        this.num_event = 1;
        return;
      }
      
      let payloads = [];
      let iteration_count = Math.min(this.num_event - this.num_done, Math.floor(Math.random()*500));
      for (let i = 0; i < iteration_count; i++) {
        const correspondent = this.correspondent_list[Math.floor(Math.random() * this.correspondent_list.length)];
        let randomTimeTs = Date.now() - (Math.floor(Math.random() * (1000*60*60*24*this.add_date)));
        let randomOrigin = correspondent.origins[Math.floor(Math.random() * correspondent.origins.length)];
        let validity = Math.floor(Math.random() * 100);
        let deficiency = null;
        if (validity < 5) {
          validity = 0;
        } else {
          validity = 1;
        }
        if (!validity) {
          deficiency = (Math.floor(Math.random() * 9)+1) * 10;
        }
        let payload = {
          origin: randomOrigin.name,
          lp_num: this.randomLprString(),
          class_name: '트럭',
          quantity: this.randomQuantity(),
          state: validity,
          deficiency: deficiency,
          image1: 'delivery/demo-image/truck9.jpg',
          image2: 'delivery/demo-image/truck1.jpg',
          freight_id: this.freight_list[Math.floor(Math.random() * this.freight_list.length)].id,
          correspondent_id: correspondent.id,
          branch_id: correspondent.branch_id,
          created_ts: randomTimeTs
        };
        payloads.push(payload);
      }

      axios.post(`/api/delivery/events/`, payloads)
        .then(response => {
          this.num_done += iteration_count;
          this.onAddDeliveryEvent();
        })
        .catch(error => {
          console.error(error);
          this.toast_show = true;
          this.toast_msg = `Failed: ${error.response.data.error}`;
          this.toast_index++;
          this.toast_color = 'danger';
          this.test_loading = false;
        })
        // .finally(_ => {
        //   this.num_event = 1;
        // })
    },
    randomLprString() {
      let result = '';
      let location = ['서울','경기','강원','충북','충남','전북','전남','경북','경남'];
      let num_chars = '0123456789';
      let ko_chars = '가나다라마바사아자차카타파하거너더러머버서어저처커터퍼허고노도로모보소오조초코토포호구누두루무부수우주추쿠투푸후';
      let charactersLength = num_chars.length;
      let locLength = location.length;
      result = location[Math.floor(Math.random()*locLength)];
      for ( let i = 0; i < 2; i++ ) {
        result += num_chars.charAt(Math.floor(Math.random() * charactersLength));
      }
      result += ko_chars.charAt(Math.floor(Math.random() * ko_chars.length));
      for ( let i = 0; i < 4; i++ ) {
        result += num_chars.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    },
    randomQuantity() {
      let result = '';
      let quans = [ 16, 16.5, 17, 17.5, 18 ];
      let quansLength = quans.length;
      result = quans[Math.floor(Math.random() * quansLength)];
      return result;
    },
    getFreight: function() {
      let query_string = '';
      const params = {
        ipp: 50,
        page: 1
      };
      if (params) {
        query_string = '?' + qs.stringify(params)
      }
      axios.get(`/api/delivery/freights/${query_string}`)
        .then(result => {
          this.freight_list = result.data.list;
        })
        .catch(e => {
          console.error(e);
        })
    },
    getCorrespondent: function() {
      let query_string = '';
      const params = {
        ipp: 50,
        page: 1
      };
      if (params) {
        query_string = '?' + qs.stringify(params)
      }
      axios.get(`/api/delivery/correspondents/${query_string}`)
        .then(result => {
          this.correspondent_list = result.data.list;
        })
        .catch(e => {
          console.error(e);
        })
    },


    onAddParkingEvent: function() {
      this.loading_text = `테스트 데이터 추가중입니다... (${this.num_done}/${this.num_event})`;
      this.test_loading = true;
      if (this.num_done >= this.num_event) {
        this.test_loading = false;
        this.toast_show = true;
        this.toast_msg = `${this.num_event} 개의 테스트 데이터가 추가되었습니다.`;
        this.toast_index++;
        this.toast_color = 'success';
        this.num_done = 0;
        this.num_event = 1;
        return;
      }
      let payloads = [];
      let iteration_count = Math.min(this.num_event - this.num_done, Math.floor(Math.random()*500));
      const spaces = [
        // {branch_id: 19, device_id: 8, ch: 1, index_column1: 'A-01'},
        // {branch_id: 19, device_id: 8, ch: 1, index_column1: 'A-02'},
        // {branch_id: 19, device_id: 8, ch: 1, index_column1: 'A-03'},
        // {branch_id: 19, device_id: 8, ch: 1, index_column1: 'A-04'},
        // {branch_id: 19, device_id: 8, ch: 1, index_column1: 'A-05'},
        // {branch_id: 19, device_id: 8, ch: 1, index_column1: 'A-06'},
        // {branch_id: 19, device_id: 8, ch: 1, index_column1: 'A-11'},
        // {branch_id: 19, device_id: 8, ch: 1, index_column1: 'A-12'},
        // {branch_id: 19, device_id: 8, ch: 1, index_column1: 'A-13'},
        // {branch_id: 19, device_id: 8, ch: 1, index_column1: 'A-14'},
        // {branch_id: 19, device_id: 8, ch: 1, index_column1: 'A-15'},
        // {branch_id: 19, device_id: 8, ch: 1, index_column1: 'A-16'},
        // {branch_id: 19, device_id: 8, ch: 2, index_column1: 'C-01'},
        // {branch_id: 19, device_id: 8, ch: 2, index_column1: 'C-02'},
        // {branch_id: 19, device_id: 8, ch: 2, index_column1: 'C-03'},
        // {branch_id: 19, device_id: 8, ch: 2, index_column1: 'C-04'},
        // {branch_id: 19, device_id: 8, ch: 2, index_column1: 'C-05'},
        // {branch_id: 19, device_id: 8, ch: 2, index_column1: 'C-11'},
        // {branch_id: 19, device_id: 8, ch: 2, index_column1: 'C-12'},
        // {branch_id: 19, device_id: 8, ch: 2, index_column1: 'C-13'},
        // {branch_id: 19, device_id: 8, ch: 2, index_column1: 'C-14'},
        // {branch_id: 19, device_id: 8, ch: 2, index_column1: 'C-15'},
        // {branch_id: 19, device_id: 8, ch: 3, index_column1: 'B-01'},
        // {branch_id: 19, device_id: 8, ch: 3, index_column1: 'B-02'},
        // {branch_id: 19, device_id: 8, ch: 3, index_column1: 'B-03'},
        // {branch_id: 19, device_id: 8, ch: 3, index_column1: 'B-04'},
        // {branch_id: 19, device_id: 8, ch: 3, index_column1: 'B-05'},
        // {branch_id: 19, device_id: 8, ch: 3, index_column1: 'B-11'},
        // {branch_id: 19, device_id: 8, ch: 3, index_column1: 'B-12'},
        // {branch_id: 19, device_id: 8, ch: 3, index_column1: 'B-13'},
        // {branch_id: 19, device_id: 8, ch: 3, index_column1: 'B-14'},
        // {branch_id: 19, device_id: 8, ch: 4, index_column1: 'D-01'},
        // {branch_id: 19, device_id: 8, ch: 4, index_column1: 'D-02'},
        // {branch_id: 19, device_id: 8, ch: 4, index_column1: 'D-03'},
        // {branch_id: 19, device_id: 8, ch: 4, index_column1: 'D-04'},
        // {branch_id: 19, device_id: 8, ch: 4, index_column1: 'D-05'},
        // {branch_id: 19, device_id: 8, ch: 4, index_column1: 'D-11'},
        // {branch_id: 19, device_id: 8, ch: 4, index_column1: 'D-12'},
        // {branch_id: 19, device_id: 8, ch: 4, index_column1: 'D-13'},
        // {branch_id: 19, device_id: 8, ch: 4, index_column1: 'D-14'},
        // {branch_id: 19, device_id: 8, ch: 4, index_column1: 'D-15'},
        // {branch_id: 24, device_id: 8, ch: 5, index_column1: '7-bgw'},
        // {branch_id: 24, device_id: 8, ch: 5, index_column1: '7-kns'},
        // {branch_id: 24, device_id: 8, ch: 3, index_column1: '7-jjy'},
        // {branch_id: 24, device_id: 8, ch: 5, index_column1: '7-ssh'},
        // {branch_id: 24, device_id: 8, ch: 5, index_column1: '7-usj'},
        // {branch_id: 24, device_id: 8, ch: 5, index_column1: '7-pjg'},
        // {branch_id: 18, device_id: 8, ch: 6, index_column1: '1-a-01'},
        // {branch_id: 18, device_id: 8, ch: 6, index_column1: '1-a-02'},
        // {branch_id: 18, device_id: 8, ch: 6, index_column1: '1-a-03'},
        // {branch_id: 18, device_id: 8, ch: 6, index_column1: '1-a-04'},
        // {branch_id: 18, device_id: 8, ch: 6, index_column1: '1-a-05'},
        // {branch_id: 18, device_id: 8, ch: 6, index_column1: '1-a-06'},
        // {branch_id: 18, device_id: 8, ch: 6, index_column1: '1-a-07'},
        // {branch_id: 18, device_id: 8, ch: 6, index_column1: '1-a-08'},
        // {branch_id: 18, device_id: 8, ch: 6, index_column1: '1-a-09'},
        // {branch_id: 18, device_id: 8, ch: 6, index_column1: '1-a-10'},
        // {branch_id: 18, device_id: 8, ch: 6, index_column1: '1-b-06'},
        // {branch_id: 18, device_id: 8, ch: 6, index_column1: '1-b-07'},
        // {branch_id: 18, device_id: 8, ch: 6, index_column1: '1-b-08'},
        // {branch_id: 18, device_id: 8, ch: 6, index_column1: '1-b-09'},
        // {branch_id: 18, device_id: 8, ch: 6, index_column1: '1-b-10'},
        // {branch_id: 18, device_id: 8, ch: 6, index_column1: '1-b-01'},
        // {branch_id: 18, device_id: 8, ch: 6, index_column1: '1-b-02'},
        // {branch_id: 18, device_id: 8, ch: 6, index_column1: '1-b-03'},
        // {branch_id: 18, device_id: 8, ch: 6, index_column1: '1-b-04'},
        // {branch_id: 18, device_id: 8, ch: 6, index_column1: '1-b-05'},
        // {branch_id: 18, device_id: 8, ch: 6, index_column1: '1-C-01'},
        // {branch_id: 18, device_id: 8, ch: 6, index_column1: '1-C-02'},
        // {branch_id: 18, device_id: 8, ch: 6, index_column1: '1-C-03'},
        // {branch_id: 18, device_id: 8, ch: 6, index_column1: '1-C-04'},
        // {branch_id: 18, device_id: 8, ch: 6, index_column1: '1-C-05'},
        // {branch_id: 18, device_id: 8, ch: 6, index_column1: '1-C-06'},
        // {branch_id: 18, device_id: 8, ch: 6, index_column1: '1-C-07'},
        // {branch_id: 18, device_id: 8, ch: 6, index_column1: '1-C-08'},
        // {branch_id: 18, device_id: 8, ch: 6, index_column1: '1-C-09'},
        // {branch_id: 18, device_id: 8, ch: 6, index_column1: '1-C-10'},
        // {branch_id: 18, device_id: 8, ch: 6, index_column1: '1-D-01'},
        // {branch_id: 18, device_id: 8, ch: 6, index_column1: '1-D-02'},
        // {branch_id: 18, device_id: 8, ch: 6, index_column1: '1-D-03'},
        // {branch_id: 18, device_id: 8, ch: 6, index_column1: '1-D-04'},
        // {branch_id: 18, device_id: 8, ch: 6, index_column1: '1-D-05'},
        // {branch_id: 18, device_id: 8, ch: 6, index_column1: '1-D-06'},
        // {branch_id: 18, device_id: 8, ch: 6, index_column1: '1-D-11'},
        // {branch_id: 18, device_id: 8, ch: 6, index_column1: '1-D-12'},
        // {branch_id: 18, device_id: 8, ch: 6, index_column1: '1-D-13'},
        // {branch_id: 18, device_id: 8, ch: 6, index_column1: '1-D-14'},
        // {branch_id: 18, device_id: 8, ch: 6, index_column1: '1-D-15'},
        // {branch_id: 18, device_id: 8, ch: 6, index_column1: '1-D-16'},
        {branch_id: 39, device_id: 8, ch: 9, index_column1: '29-A-01'},
        {branch_id: 39, device_id: 8, ch: 9, index_column1: '29-A-02'},
        {branch_id: 39, device_id: 8, ch: 9, index_column1: '29-A-03'},
        {branch_id: 39, device_id: 8, ch: 9, index_column1: '29-A-04'},
        {branch_id: 39, device_id: 8, ch: 9, index_column1: '29-A-05'},
        {branch_id: 39, device_id: 8, ch: 9, index_column1: '29-A-06'},
        {branch_id: 39, device_id: 8, ch: 9, index_column1: '29-A-11'},
        {branch_id: 39, device_id: 8, ch: 9, index_column1: '29-A-12'},
        {branch_id: 39, device_id: 8, ch: 9, index_column1: '29-A-13'},
        {branch_id: 39, device_id: 8, ch: 9, index_column1: '29-A-14'},
        {branch_id: 39, device_id: 8, ch: 9, index_column1: '29-A-15'},
        {branch_id: 39, device_id: 8, ch: 9, index_column1: '29-A-16'},
        {branch_id: 39, device_id: 8, ch: 10, index_column1: '29-C-01'},
        {branch_id: 39, device_id: 8, ch: 10, index_column1: '29-C-02'},
        {branch_id: 39, device_id: 8, ch: 10, index_column1: '29-C-03'},
        {branch_id: 39, device_id: 8, ch: 10, index_column1: '29-C-04'},
        {branch_id: 39, device_id: 8, ch: 10, index_column1: '29-C-05'},
        {branch_id: 39, device_id: 8, ch: 10, index_column1: '29-C-11'},
        {branch_id: 39, device_id: 8, ch: 10, index_column1: '29-C-12'},
        {branch_id: 39, device_id: 8, ch: 10, index_column1: '29-C-13'},
        {branch_id: 39, device_id: 8, ch: 10, index_column1: '29-C-14'},
        {branch_id: 39, device_id: 8, ch: 10, index_column1: '29-C-15'},
        {branch_id: 39, device_id: 8, ch: 11, index_column1: '29-B-01'},
        {branch_id: 39, device_id: 8, ch: 11, index_column1: '29-B-02'},
        {branch_id: 39, device_id: 8, ch: 11, index_column1: '29-B-03'},
        {branch_id: 39, device_id: 8, ch: 11, index_column1: '29-B-04'},
        {branch_id: 39, device_id: 8, ch: 11, index_column1: '29-B-05'},
        {branch_id: 39, device_id: 8, ch: 11, index_column1: '29-B-11'},
        {branch_id: 39, device_id: 8, ch: 11, index_column1: '29-B-12'},
        {branch_id: 39, device_id: 8, ch: 11, index_column1: '29-B-13'},
        {branch_id: 39, device_id: 8, ch: 11, index_column1: '29-B-14'},
        {branch_id: 39, device_id: 8, ch: 12, index_column1: '29-D-01'},
        {branch_id: 39, device_id: 8, ch: 12, index_column1: '29-D-02'},
        {branch_id: 39, device_id: 8, ch: 12, index_column1: '29-D-03'},
        {branch_id: 39, device_id: 8, ch: 12, index_column1: '29-D-04'},
        {branch_id: 39, device_id: 8, ch: 12, index_column1: '29-D-05'},
        {branch_id: 39, device_id: 8, ch: 12, index_column1: '29-D-11'},
        {branch_id: 39, device_id: 8, ch: 12, index_column1: '29-D-12'},
        {branch_id: 39, device_id: 8, ch: 12, index_column1: '29-D-13'},
        {branch_id: 39, device_id: 8, ch: 12, index_column1: '29-D-14'},
        {branch_id: 39, device_id: 8, ch: 12, index_column1: '29-D-15'},
      ]
      for (let i = 0; i < iteration_count; i++) {
        let randomTimeTs = (Date.now() - (Math.floor(Math.random() * (1000*60*60*24*this.add_date)))) / 1000;
        let randomSpace = spaces[Math.floor(Math.random() * spaces.length)];
        let randomDir = Math.floor(Math.random() * 2) === 0 ? 'enter':'exit'
        let payload = {
          event_type: 'enter_exit',
          meta_str: null,
          ts: randomTimeTs,
          index_column1: randomSpace.index_column1,
          index_column2: randomDir,
          index_column3: 'car',
          branch_id: randomSpace.branch_id,
          device_id: randomSpace.device_id,
          ch: randomSpace.ch,
        };
        payloads.push(payload);
      }

      axios.post(`/api/dummy/events/`, payloads)
        .then(response => {
          this.num_done += iteration_count;
          this.onAddParkingEvent();
        })
        .catch(error => {
          console.error(error);
          this.toast_show = true;
          this.toast_msg = `Failed: ${error.response.data.error}`;
          this.toast_index++;
          this.toast_color = 'danger';
          this.test_loading = false;
        })
    },

    onAddParkingOccupancy: function() {
      this.loading_text = `테스트 데이터 추가중입니다... (${this.num_done}/${this.num_event})`;
      this.test_loading = true;
      if (this.num_done >= this.num_event) {
        this.test_loading = false;
        this.toast_show = true;
        this.toast_msg = `${this.num_event} 개의 테스트 데이터가 추가되었습니다.`;
        this.toast_index++;
        this.toast_color = 'success';
        this.num_done = 0;
        this.num_event = 1;
        return;
      }
      let payloads = [];
      let iteration_count = Math.min(this.num_event - this.num_done, Math.floor(Math.random()*500));
      const places = [
        {id: 1, cnt: 20},
        {id: 2, cnt: 41},
        {id: 7, cnt: 6}
      ];

      for (let i = 0; i < iteration_count; i++) {
        let randomTimeTs = (Date.now() - (Math.floor(Math.random() * (1000*60*60*24*this.add_date)))) / 1000;
        for (let place of places) {
          let randomFree = Math.floor(Math.random() * place.cnt);
          let randomOccupied = place.cnt - randomFree;
          let payload = {
            ts: randomTimeTs,
            place_id: place.id,
            free: randomFree,
            occupied: randomOccupied
          };
          payloads.push(payload);
        }
      }

      axios.post(`/api/dummy/occupancy/`, payloads)
        .then(response => {
          this.num_done += iteration_count;
          this.onAddParkingOccupancy();
        })
        .catch(error => {
          console.error(error);
          this.toast_show = true;
          this.toast_msg = `Failed: ${error.response.data.error}`;
          this.toast_index++;
          this.toast_color = 'danger';
          this.test_loading = false;
        })
    }
  }
}
</script>
